<template>
	<div id="app">
		<!--头部-->
		<vHeader v-if="show"></vHeader>

		<!--导航栏-->
		<vNav v-if="show" :datas="navDatas"></vNav>

		<!--主体-->
		<router-view/>

		<!--底部-->
		<vFooter v-if="show" :fDatas="footerDatas"></vFooter>
	</div>
</template>
<script>
	import vHeader from "@/components/Header.vue";
	import vNav from "@/components/Nav.vue";
	import vFooter from "@/components/Footer.vue";

	export default {
		name: "",
		data() {
			return {
				show: true,
				pageSize: 10,
				navDatas: [],
				footerDatas: []
			};
		},
		mounted() {
			this.getData();
		},
		methods: {
			// 获取数据
			getData() {
				let param = {
					gglx: 1,
					pageSize: this.pageSize
				};
				this.apis.api.getIndexList(param).then(res => {
					if (res.data.code == "01") {
						let datas = res.data.dat[0];
						this.navDatas = datas && datas.zxdt;
						this.footerDatas = datas && datas.info;

						sessionStorage.setItem("tradingurl", datas.tradingurl);
					}
				});
			}
		},
		components: {
			vHeader,
			vFooter,
			vNav
		}
	};
</script>
<style lang="scss">
	@import "~@/assets/scss/base";
	// @import "~@/assets/scss/scrollbar";
	html,
	body {
		margin: 0;
		padding: 0;
		height: 100%;
	}

	#app {
		font-family: "Avenir", Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		height: 100%\0;
		min-height: 937px;
		background: url("./assets/images/bgc.png") no-repeat;
		background-size: cover;
		background-attachment: fixed;
		overflow: auto;
	}

	// #nav {
	//   padding: 30px;

	//   a {
	//     font-weight: bold;
	//     color: #2c3e50;

	//     &.router-link-exact-active {
	//       color: #42b983;
	//     }
	//   }
	// }
</style>
