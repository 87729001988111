import axios from "axios";

axios.defaults.headers.post['Content-Type'] = 'application/json';

// http request 请求拦截
axios.interceptors.request.use(
  config => {
    return config;
  },
  err => {
    return Promise.reject(err);
  })

//拦截 后端传过来的值
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {

  })


/******相关接口*****/
//反向代理

// let ipconfig = "http://192.168.1.249:8085";

export default {
  /**
      *	get 请求示例 两种写法 选其一种
    *
      * 	getAdminUser(param) {
    *		return axios.get('/admin/adminUser/list?perPage=' + param.perPage + '&page=' + param.page + '&name=' + param.name);
    *	},
    *
    *	getAdminUser(param) {
    *		return axios.get('/admin/adminUser/list', {
    *			params: param
    *		});
    *	},
    *
    *	post 请求示例
    *
    *	addAdmin(param) {
    *		return axios.post('/admin/adminUser/add', param);
    *	},
      *
      */


  // 查询字典表
  getSerchZDB(param) {
    return axios.get('/pub/getdict', {
      params: param
    });
  },

  // 查询字典表2
  getSerchZDB2(param) {
    return axios.get(sessionStorage.getItem("tradingurl") + '/pub/getdict', {
      params: param
    });
  },

  // 首页列表信息
  getIndexList(param) {
    return axios.get('/index/list', {
      params: param
    });
  },

  // 首页--公示公告
  getIndexNoticeList(param) {
    return axios.get('/index/gsgg', {
      params: param
    });
  },

  // 新闻动态
  getNewsList(param) {
    return axios.get('/work/xlist', {
      params: param
    });
  },

  // 政策法规
  getLawsList(param) {
    return axios.get('/work/zlist', {
      params: param
    });
  },

  // 公示公告
  getNoticeList(param) {
    return axios.get('/work/glist', {
      params: param
    });
  },

  // 交易统计--公告数量统计
  getTradeGGSLTJ(param) {
    return axios.get('/work/ggsllist', {
      params: param
    });
  },
  // 交易统计--其他
  getTradeOhtersList(param) {
    // return axios.get('http://192.168.1.249:8090/pds/statistics/jytj', {
    return axios.get('/work/jytj', {
      params: param
    });
  },


  // 联系我们
  getContactUsDatas(param) {
    return axios.get('/work/lxwm', {
      params: param
    });
  },
  // 联系我们--富文本
  getInitBrief(param) {
    return axios.get('/work/brief', {
      params: param
    });
  },


  // 公告详情
  getGSGGDetail(param) {
    return axios.get('/work/jyinfo', {
      params: param
    });
  },

  // 其他详情
  getListDetail(param) {
    return axios.get('/work/datainfo', {
      params: param
    });
  },


  // 搜索--Header
  getSearchKeywords(param) {
    return axios.get('/index/seach', {
      params: param
    });
  },




}