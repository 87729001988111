import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
// import VueHtml5Editor from 'vue-html5-editor'
// fade/zoom 等
// import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
// import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
// 引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 引入高德地图
import VueAMap from 'vue-amap';

Vue.use(VueAMap);


// 引入 后端接口api
import apis from '@/apis/apis.js'

Vue.prototype.apis = apis


// 过滤后台时间戳
import "@/public/filter.js"

// 初始化vue-amap
VueAMap.initAMapApiLoader({
  // 高德的key
  key: '8bcd5d53aee035144d261311c2aba4a4',
  // 插件集合
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 高德 sdk 版本，默认为 1.4.4
  v: '1.4.4'
});

//重新写入 toFixed 方法
Number.prototype.toFixed = function (n) {

	if (n > 20 || n < 0) {
		throw new RangeError('toFixed() digits argument must be between 0 and 20');
	}
	const number = this;
	if (isNaN(number) || number >= Math.pow(10, 21)) {
		return number.toString();
	}

	if (typeof (n) == 'undefined' || n == 0) {
		return (Math.round(number)).toString();
	}
	let result = number.toString();
	const arr = result.split('.');
	// 整数的情况
	if (arr.length < 2) {
		result += '.';
		for (let i = 0; i < n; i += 1) {
			result += '0';
		}
		return result;
	}
	const integer = arr[0];
	const decimal = arr[1];
	if (decimal.length == n) {
		return result;
	}

	if (decimal.length < n) {
		for (let i = 0; i < n - decimal.length; i += 1) {
			result += '0';
		}
		return result;
	}
	result = integer + '.' + decimal.substr(0, n);
	const last = decimal.substr(n, 1);
	// 四舍五入，转换为整数再处理，避免浮点数精度的损失
	if (parseInt(last, 10) >= 5) {
		const x = Math.pow(10, n);
		result = (Math.round((parseFloat(result) * x)) + 1) / x;
		result = result.toFixed(n);
	}
	return result;
}

Array.prototype.indexOf = function(val) {
	for (var i = 0; i < this.length; i++) {
		if (this[i] == val) return i;
	}
	return -1;
};


// Vue.component(CollapseTransition.name, CollapseTransition)
// Vue.use(VueHtml5Editor); //富文本编辑器
Vue.config.productionTip = false
Vue.use(ElementUI)
// var isLogin=localStorage.getItem("islogin");
// console.log(isLogin,'0001110');
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
