<template>
	<div id="header">
		<div class="container">
			<div class="title" style="display: flex;align-items: center;">
				<img class="logo fl" src="../assets/images/logo.png" alt>
				<div class="fl" style="line-height: 30px;">
					<span class="text-white text-32">平顶山市大地自然资源交易中心</span>
<!--					<br/>-->
<!--					<span class="text-white text-32">平顶山市自然资源交易平台</span>-->
				</div>
				<div class="searchContent">
					<input
						type="text"
						placeholder="请输入搜索关键词进行搜索"
						class="searchInput"
						v-model="keyValue"
						@keyup.enter="toSearch()"
					>
					<span class="search" @click="toSearch"><b></b></span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "",
		data() {
			return {
				keyValue: ""
			};
		},
		watch: {
			$route: {
				handler: function (value, oldVal) {
					// console.log(1111111);

					//监听路有变化匹配
					scrollTo(0, 0);
				},
				// 深度观察监听
				deep: true
			}
		},
		methods: {
			// 查询
			toSearch() {
				this.$router.push({
					name: "search",
					query: {
						key: this.keyValue
					}
				});
				this.keyValue = "";
			}
		}
	};
</script>
<style lang="scss">
	#header {
		text-align: left;
		margin-bottom: 10px;

		.container {
			position: relative;

			.title {
				padding: 40px 0;
				height: 80px;
				line-height: 80px;
				box-sizing: content-box;

				.logo {
					position: relative;
					margin-right: 10px;
					top: -7px;
					width: 5%;
					// vertical-align: initial;
				}

				.searchContent {
					position: absolute;
					top: 45px;
					right: -5px;
					width: 340px;
					height: 50px;

					.searchInput {
						width: 300px;
						height: 40px;
						line-height: 40px;
						border-radius: 5px;
						padding-left: 15px;
						color: #fff;
						background-color: rgba(51, 51, 51, 0.498039215686275);
					}

					.searchInput::-webkit-input-placeholder {
						color: #999;
					}

					.searchInput:-ms-input-placeholder {
						// IE10+
						color: #999;
					}

					.searchInput:-moz-placeholder {
						// Firefox4-18
						color: #999;
					}

					.searchInput::-moz-placeholder {
						// Firefox19+
						color: #999;
					}

					.search {
						display: inline-block;
						position: relative;
						top: 21px;
						left: -5px;
						width: 40px;
						height: 40px;
						background: #70b603;
						cursor: pointer;

						&:hover {
							opacity: 0.8;
						}

						&:active {
							opacity: 1;
						}

						b {
							display: inline-block;
							position: relative;
							top: -10px;
							left: 5px;
							width: 30px;
							height: 30px;
							background: url("../assets/images/searicon.png") no-repeat;
						}
					}
				}
			}
		}
	}
</style>
