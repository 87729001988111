<template>
  <div class="foot container">
    <div class="footBox">
      <div class="website">
        <div class="yqlj fl">
          <img src="../assets/images/yqlj.png" alt>
          <p class="words">友情链接</p>
        </div>
        <div class="address fl">
          国家网站
          <select>
            <option value="value">请选择</option>
            <option value="http://www.mnr.gov.cn/">自然资源部</option>
          </select>
          省级网站
          <select>
            <option value="value">请选择</option>
            <option value="https://www.henan.gov.cn/">河南省人民政府</option>
            <option value="http://dnr.henan.gov.cn/">河南省省自然资源厅</option>
            <option value="http://www.hnggzy.com/hnsggzy/">河南省公共资源交易中心</option>
          </select>
          市级网站
          <select>
            <option value="value">请选择</option>
            <option value="http://www.pds.gov.cn/">平顶山市人民政府</option>
            <option value="http://www.pdsgh.gov.cn/">平顶山市自然资源和规划局</option>
          </select>
          县区网站
          <select>
            <option value="value">请选择</option>
            <option value="http://www.zhq.gov.cn/">湛河区</option>
            <option value="http://www.pdsgxq.gov.cn/">高新区</option>
            <option value="http://www.yexian.gov.cn/">叶县</option>
            <option value="http://www.hnls.gov.cn/">鲁山县</option>
            <option value="http://www.jiaxian.gov.cn/">郏县</option>
            <option value="http://www.baofeng.gov.cn/">宝丰县</option>
            <option value="http://www.ruzhou.gov.cn/">汝州市</option>
            <option value="http://www.shilongqu.gov.cn/">石龙区</option>
            <option value="http://www.weidong.gov.cn/">卫东区</option>
            <option value="http://www.xinhuaqu.gov.cn/">新华区</option>
            <option value="http://sfq.pds.gov.cn/">新城区</option>
          </select>
          其他网站
          <select>
            <option value="value">请选择</option>
            <!-- <option value="https://www.tuliu.com/">百度地图</option> -->
          </select>
        </div>
      </div>

      <div class="footList">
        <ul class="tl">
          <li>{{copyrightName}}：{{copyrightVal}}</li>
          <li><a href="https://beian.miit.gov.cn/" target="_blank" style="cursor:pointer;color: white">{{providerName}}：{{providerVal}}</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["fDatas"],
  name: "footer2",
  data() {
    return {
      copyrightName: "",
      copyrightVal: "",
      providerName: "",
      providerVal: ""
      // initData: []
    };
  },
  mounted() {
    this.goodLink();
  },
  watch: {
    fDatas(newVal, oldVal) {
      newVal.forEach(e => {
        if (e.keyy == "copyright") {
          this.copyrightName = e.uname;
          this.copyrightVal = e.valuess;
        }
        if (e.keyy == "provider") {
          this.providerName = e.uname;
          this.providerVal = e.valuess;
        }
      });
    }
  },
  created() {},
  methods: {
    goodLink: function() {
      $(".footBox select").bind("change", function() {
        var option = this.options[this.selectedIndex];
        if (option.value !== "value") {
          window.open(option.value);
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.foot {
  // position: fixed;
  // bottom: 0;
  // left: 50%;
  // margin-left: -600px;
  margin-top: 20px;
  height: 160px;
  // padding: 0 20px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.498039);
  .footBox {
    width: 1200px;
    margin: 0 auto;
    color: #fff;
    // padding-top: 20px;
    box-sizing: border-box;
    text-align: center;
    .website {
      height: 80px;
      padding: 20px;
      box-sizing: border-box;
      background-color: rgba(0, 0, 0, 0.7);
      // 友情链接
      .yqlj {
        width: 58px;
        padding-right: 15px;
        box-sizing: content-box;
        color: #02a7f0;
        border-right: 1px solid #02a7f0;
        .words {
          padding-top: 2px;
        }
      }
      .address {
        padding: 7px 0 0 15px;
        box-sizing: border-box;
        select {
          margin: 0 20px 0 9px;
          width: 120px;
          height: 30px;
          line-height: 30px;
          cursor: pointer;
          background-color: #333;
          color: #aaa;
        }
      }
    }
    .footList {
      ul {
        margin: 10px 20px;
        li {
          height: 25px;
          line-height: 25px;
        }
      }
    }
  }
}
</style>
