<template>
	<div id="header">
		<div class="container">
			<div class="nav">
				<div class="navList fl">
					<ul class="navUl">
						<li
							v-for="(item,index) in navList"
							:key="index"
							:class="{'activeNavN':isActiveClick==index}"
							@click="openViews(item.path,index)"
						>{{ item.tag }}
						</li>
					</ul>
				</div>
				<div class="lunbo fl">
					<el-carousel
						height="80px"
						direction="vertical"
						:autoplay="true"
						:loop="true"
						:interval="time"
						indicator-position="none"
					>
						<el-carousel-item v-for="(item,index) in datas" :key="index">
<!--							<a :href="item.url" target="_black">-->
								<h3 class="medium" @click="urljump(item.url)">
									<p
										class="text-14"
										style="height:40px;line-height:61px;"
									>{{ item.createtime | filterTime }}</p>
									<p
										class="text-14 text-bold hover-pointer"
										style="width: 420px;height:40px;line-height:30px;color:#02A7F0; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;"
									>{{ item.title }}</p>
								</h3>
<!--							</a>-->
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: ["isActive", "datas"],
		name: "",
		data() {
			return {
				isActiveClick: 0,
				keyValue: "",
				time: 2000,
				navList: [
					{
						tag: "首页",
						path: "/",
						index: 0
					},
					{
						tag: "新闻动态",
						path: "news",
						index: 1
					},
					{
						tag: "政策法规",
						path: "laws",
						index: 2
					},
					{
						tag: "公示公告",
						path: "notice",
						index: 3
					},
					{
						tag: "交易统计",
						path: "trade",
						index: 4
					},
					{
						tag: "联系我们",
						path: "contactUs",
						index: 4
					}
				],
				lists: [], // 上下轮播/ 最新动态
				pageSize: 10
			};
		},
		watch: {
			// 监听路有变化匹配
			$route: {
				handler: function (value, oldVal) {
					document.title = '平顶山市大地自然资源交易中心'
					this.navList.forEach((item, index) => {
						if ("/" + item.path == value.path) {
							this.isActiveClick = index;
						}
					});
					// this.isActiveClick = value.path;
					scrollTo(0, 0);
				},
				// 深度观察监听
				deep: true
			}
		},
		created() {
			if (sessionStorage.getItem("navIndex")) {
				this.isActiveClick = sessionStorage.getItem("navIndex");
			}
		},
		methods: {
			openViews: function (val, index) {
				this.$router.push(val);
				this.isActiveClick = index;
				// 解决刷新active丢失
				sessionStorage.setItem("navIndex", index);
			},
			urljump(url) {
				window.open(url)
			}
		}
	};
</script>
<style lang="scss">
	#header {
		text-align: left;
		margin-bottom: 10px;

		.container {
			position: relative;

			.nav {
				height: 80px;

				.navList {
					width: 720px;
					line-height: 80px;
					background-color: rgba(255, 255, 255, 0.6);

					.navUl {
						display: flex;
						justify-content: space-around;

						li {
							width: 120px;
							height: 80px;
							text-align: center;
							color: #000;
							font-size: 18px;
							font-weight: bold;
							cursor: pointer;
							box-sizing: border-box;

							&:hover {
								color: #fff;
								border-top: 4px solid #02a7f0;
								// background-color: #b4bbc5;
								background-color: #70b603;
							}

							&:active {
								color: #fff;
								border-top: 4px solid #02a7f0;
								background-color: #70b603;
								// background-color: #b4bbc5;
								opacity: 0.8;
							}
						}

						.activeNavN {
							color: #fff;
							border-top: 4px solid #02a7f0;
							background-color: #70b603;
						}
					}
				}

				.lunbo {
					width: calc(100% - 720px);
					line-height: 80px;
					background-color: rgba(255, 255, 255, 0.8);
				}
			}
		}

		// 上下轮播
		.el-carousel__item h3 {
			height: 80px;
			margin: 0;
			padding-left: 20px;
			color: #475669;
			font-size: 14px;
			opacity: 0.75;
		}

		.el-carousel__item:nth-child(2n) {
			// background-color: #99a9bf;
		}

		.el-carousel__item:nth-child(2n + 1) {
			// background-color: #d3dce6;
		}
	}
</style>
