import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 解决报错
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
};

// $.ajax({
// 	type: "get",
// 	url: "/index/list",
// 	async: false,
// 	data: {
// 		gglx: 1,
// 		pageSize: 20
// 	},
// 	success: function (res) {
// 		if (res.code == "01") {
// 			let datas = res.dat[0];
// 			sessionStorage.setItem("tradingurl", datas.tradingurl);
//
// 		}
// 	}
// });

export const constantRouterMap = [
	{
		path: '/', // 首页
		name: 'index',
		component: () => import('@/views/index/index.vue'),
	},
	{
		path: '/news', // 新闻动态
		name: 'news',
		component: () => import('@/views/news/index.vue'),
	},
	{
		path: '/laws', // 政策法规
		name: 'laws',
		component: () => import('@/views/laws/index.vue'),
	},
	{
		path: '/notice', // 公示公告
		name: 'notice',
		component: () => import('@/views/notice/index.vue'),
	},
	{
		path: '/trade', // 交易统计
		name: 'trade',
		component: () => import('@/views/trade/index.vue'),
	},
	{
		path: '/contactUs', // 联系我们
		name: 'contactUs',
		component: () => import('@/views/contactUs/index.vue'),
	},

	// 公告详情 -- 除搜索外的详情
	{
		path: '/noticeDetail',
		name: 'noticeDetail',
		component: () => import('@/views/index/children/noticeDetail.vue'),
	},


	// 搜索
	{
		path: '/search',
		name: 'search',
		component: () => import('@/views/index/children/search.vue'),
	},


]
const router = new Router({
	routes: constantRouterMap
})


export default router



